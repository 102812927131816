/**
* Antd Selector Style
**/

.ant-select[data-invalid="true"] .ant-select-selector {
  border: 1px solid #d64c4c !important;
}
.ant-select-selector {
  background: #ffffff;
  border: 1px solid #e7edf0 !important;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  font-family: "Pretendard Regular" !important;
}

.ant-select-selection-item {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

/* Select Option Content */
.ant-select-item-option-content {
  font-size: 16px;
  line-height: 24px;
}
.ant-select-item.ant-select-item-option {
  padding: 8px 16px;
}
.filter-select-option .ant-select-item-option-content {
  font-size: 14px;
  line-height: 18px;
}
.ant-select-dropdown .ant-select-item,
.filter-select-option .ant-select-item {
  border-radius: 0;
}

.stopEllipsis .ant-select-item-option-content {
  white-space: normal;
}

/* Select Option Disabled */
.ant-select-item.ant-select-item-option.ant-select-item-option-disabled.ant-select-item-option-selected {
  background: white;
}

/* Select Hover */
.ant-select-selector:hover,
.ant-select .ant-select-selector:hover {
  border: none;
  outline: none;
  box-shadow:
    0px 1px 2px 0px rgba(0, 0, 0, 0.25),
    0px 0px 0px 3px rgba(24, 123, 235, 0.2),
    0px 0px 0px 1px #1479eb;
}

/* Select Placeholder */
.ant-select-multiple .ant-select-selection-placeholder {
  inset-inline-start: 17px;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  padding: 0 16px;
}

/* Select Focus */
.ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-open .ant-select-selector {
  border-color: #0b3e91 !important;
  box-shadow: none !important;
}

/* Select Disable */
.ant-select-disabled .ant-select-selector {
  border: 1px solid #cfd6d9 !important;
}

/* Select input */
.ant-select-selection-search input,
.ant-select-multiple.ant-select-lg .ant-select-selector input {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}

/* Antd Selector Right Icon */
.ant-select-arrow {
  width: 18px;
  height: 18px;

  [data-icon="down"] {
    background: url("../assets/icons/icon-chevron-down.svg") no-repeat;
    background-position: center;
    background-size: contain;
    width: 18px;
    height: 18px;
  }

  [data-icon="search"] {
    background: url("../assets/icons/icon-search.svg") no-repeat;
    background-position: center;
    background-size: contain;
    width: 18px;
    height: 18px;
  }
}

.ant-select-disabled .ant-select-arrow {
  width: 18px;
  height: 18px;

  [data-icon="down"] {
    background: url("../assets/icons/icon-chevron-down-gray7.svg") no-repeat;
    background-position: center;
    background-size: contain;
    width: 18px;
    height: 18px;
  }
}

.ant-select-arrow span svg path {
  display: none;
}

/**
* Antd DatePicker Style
**/
.ant-picker-dropdown .ant-picker-date-panel .ant-picker-body {
  display: flex;
  justify-content: center;
  font-family: "Pretendard normal";
}

.ant-picker .ant-picker-input > input::placeholder {
  color: #aeb7bc;
}

/* DatePicker Week to day*/
.ant-picker-dropdown .ant-picker-content th {
  color: rgba(110, 119, 124, 1);
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
}
/* DatePicker inner day*/
.ant-picker-dropdown .ant-picker-cell {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
}
/* default Today check style */

.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-selected
  .ant-picker-cell-inner {
  background: #166dd7;
}
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-today
  .ant-picker-cell-inner::before {
  border: none;
}
/* Today */
.ant-picker-dropdown .ant-picker-today-btn {
  color: rgba(0, 0, 0, 0.88);
}
/* Range Picker Active Bar */
.ant-picker-active-bar {
  background: #0b3e91 !important;
}

/* Range Picker Custom Header */
.custom-range-picker-header .ant-picker-panel-layout {
  position: relative;
}
.custom-range-picker-header .ant-picker-panel-layout .ant-picker-panels {
  margin-top: 117.5px;
}

.custom-range-picker-header.small-header
  .ant-picker-panel-layout
  .ant-picker-panels {
  margin-top: 83px;
}

.custom-range-picker-header .ant-picker-panel-layout .ant-picker-footer {
  position: absolute;
  top: 0;
}
