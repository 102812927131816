@font-face {
  font-family: "Pretendard Black";
  src: url(../assets/fonts/Pretendard-Black.woff);
  font-weight: 900;
}

@font-face {
  font-family: "Pretendard ExtraBold";
  src: url(../assets/fonts/Pretendard-ExtraBold.woff);
  font-weight: 800;
}

@font-face {
  font-family: "Pretendard Bold";
  src: url(../assets/fonts/Pretendard-Bold.woff);
  font-weight: 700;
}

@font-face {
  font-family: "Pretendard SemiBold";
  src: url(../assets/fonts/Pretendard-SemiBold.woff);
  font-weight: 600;
}

@font-face {
  font-family: "Pretendard Medium";
  src: url(../assets/fonts/Pretendard-Medium.woff);
  font-weight: 500;
}

@font-face {
  font-family: "Pretendard Regular";
  src: url(../assets/fonts/Pretendard-Regular.woff);
  font-weight: 400;
}
