/* 
* Ck Editor Style
*/

.ck .ck-content > * {
  line-height: normal;
}

.ck h1 {
  display: block;
  font-size: 2em !important;
  font-weight: bold;
}

.ck h2 {
  display: block;
  font-size: 1.5em;
  font-weight: bold;
}

.ck h3 {
  display: block;
  font-size: 1.17em;
  font-weight: bold;
}

.ck h4 {
  display: block;
  font-size: 1em;
  font-weight: bold;
}

.ck h5 {
  display: block;
  font-size: 0.83em;
  font-weight: bold;
}

.ck h6 {
  display: block;
  font-size: 0.67em;
  font-weight: bold;
}

/* Tool Bar */
.ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar {
  border-color: #e7edf0;
  border-top-right-radius: 8px !important;
  border-top-left-radius: 8px !important;
}

/* Body  */
.ck-editor__editable {
  border-bottom-right-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
  border-color: #e7edf0 !important;
}

.ck-content {
  min-height: var(--ck-editor-min-height) !important;
  height: var(--ck-editor-height) !important;
}

/* Body Focus */
.ck.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable) {
  box-shadow: none !important;
}

/* PlaceHolder */
.ck .ck-placeholder:before,
.ck.ck-placeholder:before {
  color: #aeb7bc !important;
}

/* Font Style Bold, italic*/
.ck .ck-content strong {
  font-weight: bold;
}
.ck .ck-content i {
  font-style: italic;
}

/* @ckeditor/ckeditor5-table/theme/tablecaption.css */
.ck-content .table > figcaption {
  display: table-caption;
  caption-side: top;
  word-break: break-word;
  text-align: center;
  color: var(--ck-color-selector-caption-text);
  background-color: var(--ck-color-selector-caption-background);
  padding: 0.6em;
  font-size: 0.75em;
  outline-offset: -1px;
}
/* @ckeditor/ckeditor5-table/theme/table.css */
.ck-content .table {
  margin: 0.9em auto;
  display: table;
}
/* @ckeditor/ckeditor5-table/theme/table.css */
.ck-content .table table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  height: 100%;
  border: 1px double hsl(0, 0%, 70%);
}
/* @ckeditor/ckeditor5-table/theme/table.css */
.ck-content .table table td,
.ck-content .table table th {
  min-width: 2em;
  padding: 0.4em;
  border: 1px solid hsl(0, 0%, 75%);
}
/* @ckeditor/ckeditor5-table/theme/table.css */
.ck-content .table table th {
  font-weight: bold;
  background: hsla(0, 0%, 0%, 5%);
}
/* @ckeditor/ckeditor5-table/theme/table.css */
.ck-content[dir="rtl"] .table th {
  text-align: right;
}
/* @ckeditor/ckeditor5-table/theme/table.css */
.ck-content[dir="ltr"] .table th {
  text-align: left;
}
