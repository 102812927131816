/* 썸네일 컨테이너 */
.image-gallery-thumbnails-container {
  display: flex;
}

.image-gallery-thumbnail {
  width: auto;
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid #f1f4f6;
  transition: none;
}

.image-gallery-thumbnail:hover {
  border: 1px solid #f1f4f6;
}

.image-gallery-thumbnail.active {
  border: none;
  border-radius: 8px;
  position: relative;
}

.image-gallery-thumbnail .active-overlay {
  display: none;
}

.image-gallery-thumbnail.active .active-overlay {
  background: rgba(22, 109, 215, 0.7);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  box-shadow:
    0px 0px 0px 3px #c9def7,
    0px 0px 0px 1px #166dd7;
}
.image-gallery-thumbnail.active .active-overlay.no-data {
  background: transparent;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  box-shadow: none;
  border: 1px solid #e7edf0;
}

.image-gallery-thumbnail:first-of-type {
  margin-left: 8px;
}

.image-gallery-thumbnail + .image-gallery-thumbnail {
  margin-left: 8px;
}

.image-gallery-thumbnail:last-of-type {
  margin-right: 8px;
}

.image-gallery-thumbnails-wrapper {
  padding-top: 19px;
}

.image-gallery-thumbnails {
  width: 100%;
  overflow-x: auto;
}

.image-gallery-swipe {
  border-radius: 8px;
}

/* 썸네일 간격 */
.image-gallery-thumbnails-wrapper.thumbnails-swipe-horizontal {
  padding-top: 8px;
}
